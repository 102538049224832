import React from 'react';
import {
  Form,
  Grid,
  Header,
  Image,
  Input,
  Segment,
} from 'semantic-ui-react';
import styles from '../styles.css';


export default class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shop: '',
    };

    this.handleChange = (e, { name, value }) => {
      this.setState({ [name]: value });
    };

    this.handleSubmitShopify = () => {
      let { shop } = this.state;
      const stringToFind = '://';

      if (shop.indexOf(stringToFind) > -1) {
        const index = shop.indexOf('://') + stringToFind.length;
        shop = shop.slice(index);
      }
      location.href = `${window.shopifyAppPrefix}/auth?shop=${shop}`;
    };
  }


  render() {
    const { shop } = this.state;
    return (
      <Segment basic className={styles.signInMainSegment}>
        <Grid columns={1} stackable textAlign="center">
          <Grid.Row>
            <Grid.Column style={{ padding: '0px 50px', maxWidth: 500 }}>
              <Header as="h2">
                Kea Labs
                {
                  window.appId === 'search'
                    ? ' Smart Search'
                    : ' Recommendations'
                }
                <Header.Subheader>Enter your shop domain to log in or install this app.</Header.Subheader>
              </Header>
              <br />
              <br />
              <Form onSubmit={this.handleSubmitShopify}>
                <Form.Field>
                  <Input
                    fluid
                    placeholder="Shop address..."
                    value={shop}
                    name="shop"
                    label="https://"
                    onChange={this.handleChange}
                  />
                </Form.Field>
                <Form.Field>
                  <Form.Button fluid basic content="Sign In" />
                </Form.Field>
              </Form>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    );
  }
}
